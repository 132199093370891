import router from "next/router";
import React from "react";
import tw from "tailwind-styled-components";
import {
  BackgroundColor,
  CardsSection as CardsSectionType,
} from "./../../../../types/strapi/v2/common";
import { markdownToHtml } from "../../../../services/markdown";
import { Button, Image, RealButton } from "../../../../components";
import { getStrapiMediaUrl } from "../../../../services/strapiApi";
import { TextSection } from "./TextSection";
import { CTAType } from "../../../../types/strapi/CTAType";
import { getBackgroundColor, getTextColor } from "./utils";

interface CardsSectionProps extends CardsSectionType {}

export function CardsSection(props: CardsSectionProps): JSX.Element {
  const { cta, backgroundColor } = props;
  const bgColor = getBackgroundColor(backgroundColor);

  const textColor = getTextColor(backgroundColor);

  return (
    <Main
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      <TextSection
        textColor={textColor}
        title={props.title}
        text={props.content}
      />
      <CardsContainer>
        {props.cards.map((card, index) => {
          return (
            <Card key={`CardsSection-Card-${index}`}>
              {card && card.image && card.image.data && (
                <CardImageContainer>
                  <CardImageStyled
                    src={getStrapiMediaUrl(card.image.data.attributes)}
                    alt={card.title}
                  />
                </CardImageContainer>
              )}
              {card.title ? <CardTitle>{card.title}</CardTitle> : <></>}
              {card.content ? (
                <CardContent
                  style={{
                    color: textColor,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: markdownToHtml(card.content),
                  }}
                  className="prose break-words"
                />
              ) : (
                <></>
              )}
              {card.cta && (
                <div className="mt-5">
                  {card.cta.map((button: CTAType, i: number) => (
                    <div className="flex mb-5">
                      <RealButton
                        key={`CardsSection-RealButton-${i}`}
                        onClick={() =>
                          (window.location.href = button?.destination)
                        }
                        color={button?.bgColor}
                        className="lg:mb-0 mb-8  w-full lg:w-70"
                      >
                        {button?.label}
                      </RealButton>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          );
        })}
      </CardsContainer>

      {cta && (
        <ButtonContainer>
          <ButtonStyled
            styled="w-full lg:w-70"
            color={cta.bgColor}
            href={cta.destination}
            //target={cta.newWindow ? "_blank" : ""}
            rel="noreferrer"
          >
            {cta.label}
          </ButtonStyled>
        </ButtonContainer>
      )}
    </Main>
  );
}

const Main = tw.div`
  my-0
  lm:px-0
  w-full
  px-4
  md:px-8
  py-10
  text-left
`;

const CardsContainer = tw.div`

  lg:flex
  lg:justify-between
  lg:w-full
  lg:gap-5
`;

const Card = tw.div`
  flex-1
`;

const CardImageContainer = tw.div`
w-full
`;

const CardImageStyled = tw(Image)`
w-full
pb-2
`;

const CardTitle = tw.h3`
  text-base
  font-bold
  mb-2
  break-words
`;

const CardContent = tw.div`
w-full min-w-full
`;

const ButtonStyled = tw(Button)`
  mt-4
  mr-0
  lg:mt-0
`;

const ButtonContainer = tw.div`
  w-full
`;
